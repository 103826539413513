body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/* Estilizando a barra de rolagem */
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
  border-radius: 5px;
}

/* Recesso na área do rastreamento */
::-webkit-scrollbar-track {
  background: #ecf1f2; /* Cor de fundo da área do rastreamento */
  border-radius: 5px; /* Borda arredondada da barra de rolagem */
}

/* Cor da alça da barra de rolagem */
::-webkit-scrollbar-thumb {
  background: #3090ff; /* Cor da barra de rolagem */
  border-radius: 5px; /* Borda arredondada da barra de rolagem */
}

/* Mudar a cor quando a barra de rolagem estiver ativa (sendo arrastada) */
::-webkit-scrollbar-thumb:hover {
  background: #3090ff; /* Cor da barra de rolagem ao passar o mouse por cima */
}

:root {
  --primary-icon-color: #1270FC;
  --disabled-icon-color: '#F5F5F5'
}