
.fc-theme-standard .fc-scrollgrid {
    border: none;
}


tr > .fc-col-header-cell {
    border: none;
    border-bottom: 1px solid var(--fc-border-color);
}


.fc .fc-button-primary, .fc .fc-button-primary:disabled  {
    background-color: #fff;
    color: #1c78fc;
    font-weight: 600;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    background-color: #dfefff;
    border-color: var(--fc-button-active-border-color);
    color: #438ff9;
}

.fc .fc-button-primary:hover {
    background-color: #dfefff;
    border-color: var(--fc-button-active-border-color);
    color: #438ff9;
}


.fc .fc-button-primary:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
}

.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion{
    padding: 0px 4px;
    font-size: 12px;

    &::after {
        content: 'h';
    }
}


.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion  span{
    &::after {
        content: '';
    }
}

.dayLocale {
    font-size: 12px;
    font-weight: 600;
    color: var(--fc-day-locale-color);
}

.dayString {
    font-size: 18px;
    font-weight: 600;
    color: var(--fc-day-string-color);
}

.fc-scroller, .fc-event-main{
    overflow: hidden !important;
}

