.rce-mbox-right-notch {
  position: absolute;
  right: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: #D9FDD3;
  filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.2));
}

.rce-mbox-left-notch {
  position: absolute;
  left: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: white;
}

.rce-mbox-title {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.rce-mbox-title:hover {
  text-decoration: underline;
}

.rce-mbox-text {
  font-size: 13.6px;
  word-break: break-word;
}
